import RelayService from '@vodafonepub/relay-service';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function TagPageVisitComponent() {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const section = path.startsWith('/my/') ? path.slice(4).split('/')[0] || 'dashboard' : 'dashboard';

    RelayService.trigger('routeChange.ready', {
      page: {
        route: path,
        type: section,
      },
    });
  }, [location]);

  return null;
}

export default TagPageVisitComponent;
