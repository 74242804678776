export const determineCorrectGQLApiEndpoint = () => {
  if (!window) return '';

  const { hostname } = window.location;

  switch (true) {
    case hostname.includes('test'):
      return 'https://api.dev.aws.ziggo.io/v1/api/my-care/graphql';

    case hostname.includes('acc'):
      return 'https://api.acc.aws.ziggo.io/v1/api/my-care/graphql';

    case hostname.includes('prv'):
      return 'https://api.acc.aws.ziggo.io/v1/api/my-care-uat/graphql';
    case hostname.includes('localhost'):
    case hostname.includes('kahuna'):
      return 'http://localhost:4240/graphql';

    default:
      return 'https://api.prod.aws.ziggo.io/v1/api/my-care/graphql';
  }
};
