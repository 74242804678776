import { PRODUCTS_LOCALSTORAGE_KEYS } from '@vodafoneziggo/sandwich/generic/auth';
import { useEffect } from 'react';
import { LocalStorage } from 'utils';
import { IProductSummariesContextState } from '../products.model';
import { getCeasedProductIds } from '../products.utils';

export const useUpdateLocalstorageOnCurrentProductChange = (productsState: IProductSummariesContextState) => {
  useEffect(() => {
    const { currentProduct } = productsState;

    if (!currentProduct) return;

    LocalStorage.set(PRODUCTS_LOCALSTORAGE_KEYS.SELECTED_PRODUCT, currentProduct);
    LocalStorage.set(PRODUCTS_LOCALSTORAGE_KEYS.IS_DATA_ONLY, currentProduct.is_data_only ? 'true' : 'false');
  }, [productsState.currentProduct]);

  useEffect(() => {
    const { products } = productsState;

    if (!products) return;

    LocalStorage.set(PRODUCTS_LOCALSTORAGE_KEYS.PRODUCT_SUMMARIES, products);
    LocalStorage.set(PRODUCTS_LOCALSTORAGE_KEYS.CEASED_PRODUCTS, getCeasedProductIds(products));
  }, [productsState.products]);
};
