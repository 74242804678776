import { RED_TOGETHER } from 'config/permissions';
import { Roles } from 'models';
import { ContainerStatuses, defaultLoadingStatus } from 'models/container-statuses.model';
import { IRedTogetherState } from 'models/red-together.model';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { createContainer } from 'unstated-next';
import { isUserAllowed } from 'utils';
import api from 'utils/api';
import { makeRequestReducer } from 'utils/api/useApiRequest';
import { wrapWithCancellation } from 'utils/function';
import { redTogetherMapper } from 'utils/red-together.utils';
import { triggerApiErrorEvent } from 'utils/tracking';
import { useAuthSubscription } from './auth';

function useRedTogetherStatus() {
  const reducer = makeRequestReducer<IRedTogetherState>();
  const [state, dispatch] = useReducer(reducer, defaultLoadingStatus);
  const [isActivatingRedTogether, setIsActivatingRedTogether] = useState(false);
  const { bcId, isConsumer, roles } = useAuthSubscription();

  /**
   * Returns true when the user is allowed to view Red Together, based on his role.
   */
  const isAllowedToViewRedTogether = useMemo(
    () => !!(bcId && roles.length && isUserAllowed(isConsumer, roles, RED_TOGETHER)),
    [bcId, roles]
  );

  /**
   * Returns true when Red Together still needs activation and the user has the right role to view Red Together.
   */
  const canActivateRedTogether = useMemo(
    () => state.status === ContainerStatuses.READY && state.data.canActivateRedTogether && isAllowedToViewRedTogether,
    [state.status, isAllowedToViewRedTogether]
  );

  /**
   * Returns true if the user has activated (connected) Red Together and has the right permissions to view the Red Together pages.
   */
  const canManageRedTogether = useMemo(
    () =>
      state.status === ContainerStatuses.READY && state.data.redTogetherStatus?.connected && isAllowedToViewRedTogether,
    [state.status, isAllowedToViewRedTogether]
  );

  useEffect(() => {
    if (!bcId || !roles || roles.includes(Roles.PREPAID)) return;

    dispatch({ type: ContainerStatuses.LOADING });

    // If the user is not allowed to activate Red Together, then immediately set the container status
    // to ready without checking the api.
    if (!isAllowedToViewRedTogether) {
      dispatch({
        type: ContainerStatuses.READY,
        data: {
          redTogetherStatus: undefined,
          canActivateRedTogether: false,
          canAddRedTogether: false,
        },
      });

      return;
    }

    const cancellation = wrapWithCancellation();

    api
      .get(`/my/billing-customers/${bcId}/family-status`)
      .then(
        cancellation.wrapper(response => {
          dispatch({
            type: ContainerStatuses.READY,
            data: redTogetherMapper(response.data, roles),
          });
        })
      )
      .catch(
        cancellation.wrapper(errors => {
          dispatch({ type: ContainerStatuses.FAILED, errors: errors.errorMessages });
          triggerApiErrorEvent('red-together-status.container', errors.status, errors.errorMessages);
        })
      );

    return cancellation.cancel;
  }, [bcId, isConsumer, roles]);

  return {
    state,
    canActivateRedTogether,
    canManageRedTogether,
    isAllowedToViewRedTogether,
    isActivatingRedTogether,

    /**
     * Activates Red Together for the customer, only once and only if the account is eligible.
     *
     * @param tempDebugParameter Temporary used to detect the source of a double activation bug in our logs
     */
    /* eslint-disable-next-line require-await */
    activateRedTogether: async (tempDebugParameter?: string) => {
      if (isActivatingRedTogether || !state.data?.canActivateRedTogether) return Promise.reject();

      setIsActivatingRedTogether(true);

      return api
        .post(
          `/my/billing-customers/${bcId}/products/family${
            tempDebugParameter ? `?src=${tempDebugParameter}` : undefined
          }`
        )
        .catch(() => {
          setIsActivatingRedTogether(false);
        });
    },
  };
}

export default createContainer(useRedTogetherStatus);
