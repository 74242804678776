import { useEffect } from 'react';
import { LocalStorage } from '../../../utils/storage';
import { USER_LOCALSTORAGE_KEYS } from 'components/Auth';
import { IUserContextState } from '../user.model';
import { getBillingCustomerIndexById } from '../user.utils';

export const useUpdateLocalStorageOnUserChange = (userState: IUserContextState) => {
  /**
   * Save new user in localstorage
   */
  useEffect(() => {
    if (!userState.user) return;

    LocalStorage.set(USER_LOCALSTORAGE_KEYS.CURRENT_ACCOUNT, userState.user.user_name);
  }, [userState.user]);

  /**
   * Store active BC index in localstorage to persist this when
   * a page refresh happens, or a navigation to old my occurs.
   */
  useEffect(() => {
    if (!userState.user || !userState.activeBcId || !userState.activeBc) return;
    const bcIndex = getBillingCustomerIndexById(`${userState.activeBcId}`, userState.user.billing_customers);

    LocalStorage.set(USER_LOCALSTORAGE_KEYS.ACTIVE_BC_INDEX, bcIndex.toString());
    LocalStorage.set(USER_LOCALSTORAGE_KEYS.ACTIVE_BC_ID, userState.activeBcId);

    LocalStorage.set(USER_LOCALSTORAGE_KEYS.CURRENT_BC_CEASED, userState.activeBc.is_ceased.toString());
    LocalStorage.set(
      USER_LOCALSTORAGE_KEYS.CURRENT_BC_HAS_PAYMENT_DELAY,
      userState.activeBc.has_delayed_payment.toString()
    );
  }, [userState.activeBcId]);
};
