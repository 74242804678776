import { PRODUCTS_LOCALSTORAGE_KEYS } from '@vodafoneziggo/sandwich/generic/auth';
import { IProductSummary } from 'pages/Products/models';
import { useEffect } from 'react';
import { LocalStorage } from 'utils';
import { IProductSummariesContextState, SetProductSummariesState } from '../products.model';

/**
 * If there is a current selected product in storage, preselect that again.
 * Otherwise default to the first product in the array.
 * @TODO: Check why there are 2 different local storage keys for this, and make it 1?
 */
export const useSetSelectedProductOnProductsChange = (
  productSummariesState: IProductSummariesContextState,
  setProductSummariesState: SetProductSummariesState
) => {
  useEffect(() => {
    const { products } = productSummariesState;

    const currentProductIdFromStorage = LocalStorage.get(PRODUCTS_LOCALSTORAGE_KEYS.CURRENT_PRODUCT_ID);
    const selectedProductFromStorage = LocalStorage.get(PRODUCTS_LOCALSTORAGE_KEYS.SELECTED_PRODUCT);

    const currentProduct = products.find(prod => prod.product_id === currentProductIdFromStorage);
    const selectedProduct =
      selectedProductFromStorage &&
      products.find((product: IProductSummary) => product.product_id === selectedProductFromStorage.product_id);

    if (currentProduct) {
      setProductSummariesState(state => ({ ...state, currentProduct }));

      return;
    }

    if (selectedProduct) {
      setProductSummariesState(state => ({ ...state, currentProduct: selectedProduct }));

      return;
    }

    setProductSummariesState(state => ({ ...state, currentProduct: products[0] }));
  }, [productSummariesState.products]);
};
