import { Roles } from 'models';
import { IRedTogetherState, IRedTogetherStatus } from 'models/red-together.model';

export const redTogetherMapper = (data: IRedTogetherStatus, roles: Roles[]): IRedTogetherState => {
  return {
    redTogetherStatus: {
      configured: data.configured,
      connected: data.connected,
      eligible: data.eligible,
    },
    canActivateRedTogether: data.configured && !data.connected && data.eligible,
    // True when Red Together is available (even not activated, see CC-1087) and user is admin.
    canAddRedTogether: data.configured && roles.includes(Roles.ADMIN) && (data.connected || data.eligible),
  };
};
