import Cookie from 'js-cookie';

type StorageType = 'localStorage' | 'sessionStorage';
type StoragePrefix = string;
type StorageIsSupported = boolean;

/**
 * Set storage type. Defaults to localStorage if invalid type is supplied.
 */
function setStorageType(storageType?: StorageType) {
  if (storageType && ['localStorage', 'sessionStorage'].indexOf(storageType) !== -1) {
    return storageType;
  }

  return 'localStorage';
}

/**
 * Check if given storage type is supported.
 * @param storageType
 * @returns {Boolean}
 */
function storageTypeIsSupported(storageType: StorageType) {
  try {
    window[storageType].x = 1;
    window[storageType].removeItem('x');

    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Check if localStorage is supported.
 * @returns {Boolean}
 */
function localStorageIsSupported() {
  return storageTypeIsSupported('localStorage');
}

/**
 * Check if sessionStorage is supported.
 * @returns {Boolean}
 */
function sessionStorageIsSupported() {
  return storageTypeIsSupported('sessionStorage');
}

/**
 * Wrapper class around Session and Localstorage.
 * Especially useful for Safari Incognito, where both session and localstorage
 * throwh an error if you try to us it. This class then defaults to a Cookie.
 */
class Storage {
  storagePrefix: StoragePrefix;

  storageType: StorageType;

  supported: StorageIsSupported;

  constructor(storageType?: StorageType) {
    this.storagePrefix = '';
    this.storageType = setStorageType(storageType);
    this.supported = storageTypeIsSupported(this.storageType);
  }

  /**
   * Set storage prefix
   * @param {string} prefix
   */
  public setPrefix(prefix: StoragePrefix) {
    this.storagePrefix = `${prefix}.`;
  }

  /**
   * Get storage prefix
   * @returns {string}
   */
  public getPrefix() {
    return this.storagePrefix;
  }

  /**
   * Get the prefixed storage key
   * @returns {string}
   */
  private getPrefixedStorageKey(key: string) {
    return `${this.storagePrefix}${key}`;
  }

  /**
   * Set item
   * @param key Name of the property in storage
   * @param value The data to be stored
   */
  public set(key: string, value: string | object) {
    let convertedValue = value;

    if (typeof convertedValue !== 'undefined' && convertedValue !== null) {
      if (typeof convertedValue === 'object') {
        convertedValue = JSON.stringify(convertedValue);
      }

      if (this.supported) {
        window[this.storageType].setItem(this.getPrefixedStorageKey(key), convertedValue);
      } else {
        Cookie.set(this.getPrefixedStorageKey(key), convertedValue, {
          expires: 30,
        });
      }
    }
  }

  /**
   * Get item
   * @param key Name of the property in storage.
   * @returns Value of key in storage.
   */
  public get(key: string) {
    let data = null;
    const storageKey = this.getPrefixedStorageKey(key);

    if (this.supported) {
      data = window[this.storageType].getItem(storageKey);
    } else {
      data = Cookie.get(storageKey);
    }

    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  }

  /**
   * Remove item
   * @param key Name of the property in storage.
   */
  public remove(key: string) {
    const storageKey = this.getPrefixedStorageKey(key);

    if (this.supported) {
      window[this.storageType].removeItem(storageKey);
    } else {
      Cookie.remove(storageKey);
    }
  }
}

const LocalStorage = new Storage('localStorage');
const SessionStorage = new Storage('sessionStorage');

export { localStorageIsSupported, sessionStorageIsSupported, LocalStorage, SessionStorage };
export const __TESTABLE__ = { setStorageType, storageTypeIsSupported };
